<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormInstallmentPlan
		v-else
		:agent="PAYMENT_GATEWAY.BAY"
		:is-default-installment-plan="false"
		:is-submitting="installment.create.isCreating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		:brand-id="$route.params.brandId"
		:gateway="paymentMethodDetails.gateway"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormInstallmentPlan from '@/components/FormInstallmentPlan.vue';
import { PAYMENT_GATEWAY } from '../enums/payments';

export default {
	name: 'BrandInstallmentPlanConditionCreate',

	components: {
		FormInstallmentPlan,
	},
	data() {
		return {
			PAYMENT_GATEWAY,
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', {
			installment: 'installmentPlanForGroup',
		}),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),
	},
	async created() {
		this.isLoading = true;
		await this.getPaymentMethod(this.$route.params.methodId);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			createInstallmentPlanForGroup: 'payments/createInstallmentPlanForGroup',
			getPaymentMethod: 'payments/getPaymentMethod',
		}),

		async handleSubmit(params) {
			const groupId = this.$route.params.id;
			const methodId = this.$route.params.methodId;
			const brandId = this.$route.params.brandId;

			await this.createInstallmentPlanForGroup({
				methodId,
				brandId,
				params,
			});

			this.$router.push({
				name: 'BrandInstallmentPlanEdit',
				params: { id: groupId, methodId, brandId },
			});
		},
	},
};
</script>
